import firebase from 'firebase';
import { useContext, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import Firebase, { FirebaseContext } from './context';

const useUpload = () => {
    const firebaseServer: Firebase | undefined = useContext<Firebase | undefined>(FirebaseContext);

    const [imageUrl, setImageUrl] = useState<string>('');

    const [uploading, setUploading] = useState<boolean>(false);
    const [transferred, setTransferred] = useState<number>(0);

    const resizeImage = (uploadFile: File) => {
        return new Promise<File | String | Blob | ProgressEvent>(resolve =>
            Resizer.imageFileResizer(uploadFile, 600, 600, 'JPEG', 100, 0, uri => resolve(uri), 'file'),
        );
    };

    const uploadImage = (uploadFile: File) => {
        setUploading(true);
        setTransferred(0);
        const storageRef = firebaseServer?.storageRef();
        if (storageRef) {
            resizeImage(uploadFile).then(imageResize => {
                let fileImageResize = imageResize as File;
                const task = storageRef.child(uploadFile.name).put(fileImageResize, { contentType: uploadFile.type });
                task.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    snapshot => {
                        var progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        setTransferred(progress);
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log('Upload is running');
                                break;
                        }
                    },
                    error => {
                        console.error('Error running upload task:', error);
                    },
                    () => {
                        setUploading(false);
                        task.snapshot.ref.getDownloadURL().then(downloadUrl => {
                            // console.log('FILE URL:', downloadUrl);
                            setImageUrl(downloadUrl);
                        });
                    },
                );
            });
        }
    };

    return { imageUrl, uploading, transferred, uploadImage };
};

export default useUpload;
