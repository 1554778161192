import clsx from 'clsx';
import React from 'react';
import { Avatar } from '../Avatar';
import { messageType, userType } from './../../types';
import MessageMoment from './MessageMoment';
import MessageUserName from './MessageUserName';

const Message = (props: { message: messageType; user: userType }) => {
    const isYou: boolean = props.message?.user?.id !== props.user?.id;
    return (
        <div className={clsx('d-flex flex-column align-items', `align-items-${isYou ? 'start' : 'end'}`)}>
            <div className="d-flex align-items-center mb-2">
                {isYou ? (
                    <>
                        <Avatar size={8} avatar={props.user.avatar} name={props.user.name} className="symbol symbol-circle" />
                        <div className="ms-3">
                            <MessageMoment createdAt={props.message.createdAt} />
                            <MessageUserName name={props.user.name} isYou={isYou} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="me-3">
                            <MessageMoment createdAt={props.message.createdAt} />
                            <MessageUserName name={props.user.name} isYou={isYou} />
                        </div>
                        <Avatar size={8} avatar={props.user.avatar} name={props.user.name} className="symbol symbol-circle" />
                    </>
                )}
            </div>
            {props.message?.image?.url && <img src={props.message?.image?.url} className="img-fluid shadow-4 img-thumbnail ripple img-responsive" />}{' '}
            {props.message.text && (
                <div
                    className={clsx(
                        'p-5 rounded',
                        `bg-light-${isYou ? 'info' : 'primary'}`,
                        'text-dark fw-bold mw-lg-400px',
                        `text-${isYou ? 'start' : 'end'}`,
                    )}
                    data-kt-element="message-text"
                    dangerouslySetInnerHTML={{ __html: props.message.text }}></div>
            )}
        </div>
    );
};

export default Message;
