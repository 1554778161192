import React from 'react';
import ReactAvatar, { ConfigProvider } from 'react-avatar';

export const Avatar = (props: { size: number; className?: string; avatar?: string; name?: string }) => {
    const smSize = (props.size * 8) / 5;
    const className = `w-${props.size} sm:w-${smSize} h-${props.size} sm:h-${smSize} rounded-full ${props.className}`;
    const avatar = props.avatar ? (
        <div className={className}>
            <img src={props.avatar} />
        </div>
    ) : (
        <ConfigProvider colors={['red', 'green', 'blue', '#ff4546']}>
            <div className={className}>
                <ReactAvatar size={`${props.size * 6}`} textSizeRatio={2} name={props.name} round={true} />
            </div>
        </ConfigProvider>
    );

    return avatar;
};
