import clsx from 'clsx';
import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { messageContentType } from '../types';
import Message from './message/Message';

export const MessagesContent = (props: messageContentType) => {
    const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
    const isDrawer = true;

    React.useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [props.messages]);

    return (
        <div
            id="messages"
            className={clsx('scroll-y me-n5 pe-5', {
                'h-300px h-lg-auto': !isDrawer,
            })}
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies={
                isDrawer
                    ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                    : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            }
            data-kt-scroll-wrappers={isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'}
            data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}>
            {props.messages.length > 0 &&
                props.messages.map((message, index) => {
                    const isYou: boolean = message?.user?.id !== props.user?.id;
                    const template = false;
                    const templateAttr = {};
                    if (template) {
                        Object.defineProperty(templateAttr, 'data-kt-element', {
                            value: `template-${isYou ? 'in' : 'out'}`,
                        });
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${isYou ? 'start' : 'end'} mb-10`;
                    return (
                        <div
                            key={`message${index}`}
                            className={clsx('d-flex', contentClass, 'mb-10', {
                                'd-none': template,
                            })}
                            {...templateAttr}>
                            <Message message={message} user={props.user} />
                        </div>
                    );
                })}
            {props.loading && (
                <div className="d-flex  justify-content-center mb-10 mb-10">
                    <Loader type="Puff" color="#0F0F0F" height={100} width={100} />
                </div>
            )}

            <div ref={messagesEndRef} />
        </div>
    );
};
