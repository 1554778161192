import React from 'react';
import { DrawerMessenger } from '../components/DrawerMessenger';
import Firebase, { FirebaseContext } from '../hooks/context';
import { userType } from '../types';

export const DrawerChatPage = (props: { user: userType; firebaseConfig: Object }) => {
    return (
        <FirebaseContext.Provider value={new Firebase(props.user, props.firebaseConfig)}>
            <DrawerMessenger user={props.user} />
        </FirebaseContext.Provider>
    );
};
