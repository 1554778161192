import firebase from 'firebase';
import { useContext } from 'react';
import { messageType } from 'src/types';
import Firebase, { FirebaseContext } from './context';

const useAddMessages = () => {
    const firebase: Firebase | undefined = useContext(FirebaseContext);
    const addMessage = (message: messageType): Promise<firebase.database.ThenableReference | firebase.database.Reference> | undefined => {
        // if ((message.text && message.text.length > 0) || (message.image?.url && message.image.url.length > 0)) {
        return checkBadWords(message)?.then(m => {
            if (firebase === undefined) {
                throw new Error('Undefine Firebase');
            } else {
                m.createdAt = Date.now();
                m.user = firebase.currentUser();
                return firebase.messages().push(m);
            }
        });
        // }
        // console.error('Mensage vacio');
        // return undefined;
    };

    const checkBadWords = (message: messageType): Promise<messageType> => {
        if (firebase == undefined) {
            return new Promise<messageType>(resolve => {
                return resolve(message);
            });
        }
        return firebase.firestore
            .collection('badWords')
            .get()
            .then(querySnapshot => {
                let badWords: string[] = [];
                querySnapshot.forEach(doc => {
                    if (doc.data()?.word) badWords.push(doc.data().word.trim());
                });
                let badWord = false;
                badWords.map(bw => {
                    if (message.text?.replace(/\s+/g, ' ').trim().toUpperCase().includes(bw.toUpperCase())) {
                        badWord = true;
                    }
                });
                if (badWord) {
                    message.text = 'Contenido no permitido!!!';
                }
                return message;
            })
            .catch(e => {
                console.error('Error capturando las malas palabras', e);
                return message;
            })
            .finally(() => {
                return message;
            });
    };

    return { addMessage };
};

export default useAddMessages;
