import { Field } from 'formik';
import React from 'react';

export const Input = (props: { placeholder: string; id: string }) => {
    return (
        <Field
            id={props.id}
            name={props.id}
            tabIndex="0"
            autoComplete="false"
            component="textarea"
            data-kt-element="input"
            rows="2"
            placeholder={props.placeholder}
            className="form-control form-control-flush mb-3"
        />
    );
};
