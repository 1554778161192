import { useContext, useEffect, useState } from 'react';
import { messageType } from '../types';
import Firebase, { FirebaseContext } from './context';

const useGetMessages = () => {
    const firebase: Firebase | undefined = useContext<Firebase | undefined>(FirebaseContext);

    const [messages, setMessages] = useState<messageType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (firebase === undefined) {
            throw new Error('Undefine Firebase');
        } else {
            setLoading(true);
            firebase
                .messages()
                .orderByChild('createdAt')
                .on('child_added', snapshot => {
                    const messageObject: messageType = {
                        ...snapshot.val(),
                        id: snapshot.key,
                    };
                    // firebase
                    //     .users()
                    //     .child(messageObject?.user?.id || '')
                    //     .get()
                    //     .then(user => {
                    //         const userData: userType = user.val() as userType;
                    //         setMessages(old => [
                    //             ...old,
                    //             {
                    //                 ...messageObject,
                    //                 user: userData,
                    //             },
                    //         ]);
                    //     })
                    //     .catch(e => {
                    //         console.error('Usuario no encontrado:', e);
                    //         setMessages(old => [...old, messageObject]);
                    //     });
                    setLoading(false);
                    setMessages(old => [
                        ...old,
                        {
                            ...messageObject,
                        },
                    ]);
                });
        }
        return () => {};
    }, []);
    return { messages, loading };
};

useGetMessages.propTypes = {};

export default useGetMessages;
