import clsx from 'clsx';
import React from 'react';
import { KTSVG } from '../helpers';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-3x';

export const ChatButtonPage = () => {
    return (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <div className={clsx('btn btn-icon btn-active-light-primary position-relative', toolbarButtonHeightClass)} id="kt_drawer_chat_toggle">
                <KTSVG path="/media/icons/duotone/Communication/Group-chat.svg" className={toolbarButtonIconSizeClass} />

                <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
            </div>
        </div>
    );
};
