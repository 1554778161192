// import Picker, { IEmojiData } from 'emoji-picker-react';
import { Form, Formik } from 'formik';
import React from 'react';
import { buttonAttachPhotoType, onButtonSendClickType } from '../types';
import ButtonAttachPhoto from './ButtonAttachPhoto';
import { ButtonSend } from './ButtonSend';
import { Input } from './Input';

export const SendContent = (props: {
    onButtonSendClick: onButtonSendClickType;
    onButtonAttachPhoto: buttonAttachPhotoType;
    uploading: boolean;
    transferred: number;
}) => {
    const isDrawer = true;

    const onKeyDown = (
        event: React.KeyboardEvent<HTMLFormElement>,
        values: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit(values, setFieldValue);
            event.stopPropagation();
        }
    };

    const onSubmit = (values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const { messageText } = values;
        if (messageText.length > 0) {
            props.onButtonSendClick(messageText);
            setFieldValue('messageText', '');
        }
    };

    return (
        <div className="card-footer pt-4" id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}>
            <Formik
                initialValues={{ messageText: '' }}
                onSubmit={(values, { setFieldValue }) => {
                    onSubmit(values, setFieldValue);
                }}>
                {({ values, setFieldValue }) => (
                    <Form
                        onKeyDown={e => {
                            onKeyDown(e, values, setFieldValue);
                        }}>
                        <Input id="messageText" placeholder="Escribe tu mensaje" />
                        {/* <div>{chosenEmoji}</div> */}
                        <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-2">
                                <ButtonAttachPhoto handleUploadFile={props.onButtonAttachPhoto} />
                            </div>
                            <ButtonSend />
                            {/* <Picker onEmojiClick={onEmojiClick} /> */}
                        </div>
                        <div className="mt-5" hidden={!props.uploading}>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${props.transferred}%` }}
                                    aria-valuenow={props.transferred}
                                    aria-valuemin={0}
                                    aria-valuemax={100}>
                                    {`${props.transferred}%`}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
