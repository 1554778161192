import React, { useRef } from 'react';
import { GoCloudUpload as Icon } from 'react-icons/go';
import { buttonAttachPhotoType } from '../types';

const ButtonAttachPhoto = (props: { handleUploadFile: buttonAttachPhotoType }) => {
    const ref = useRef<any>();

    const handleButtonClick = () => {
        // console.log('Upload File');
        if (ref) {
            ref.current.click();
        }
    };

    return (
        <>
            <button className="btn btn-primary btn-icon btb-md mr-1" type="button" title="Adicionar imagen" onClick={handleButtonClick}>
                <Icon />
            </button>
            <input type="file" accept=".png, .jpg, .jpeg" hidden ref={ref} onChange={props.handleUploadFile} />
        </>
    );
};

export default ButtonAttachPhoto;
