import { useContext, useEffect, useState } from 'react';
import { userType } from '../types';
import Firebase, { FirebaseContext } from './context';

const useUpsertUserData = (props: { user: userType }) => {
    const firebase: Firebase | undefined = useContext<Firebase | undefined>(FirebaseContext);

    const [user, setUser] = useState<userType>({ id: '' });

    useEffect(() => {
        if (firebase === undefined) {
            throw new Error('Undefine Firebase');
        }
        firebase.user(props.user.id).set(props.user, () => {
            setUser(props.user);
        });
    }, []);

    return { user };
};

export default useUpsertUserData;
