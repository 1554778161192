/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect } from 'react';
import { useAddMessages, useGetMessages, useUpload, useUpsertUserData } from '../hooks';
import { buttonAttachPhotoType, onButtonSendClickType, userType } from '../types';
import { ChatUserData } from './ChatUserData';
import { MessagesContent } from './MessagesContent';
import { SendContent } from './SendContent';

export const DrawerMessenger = (props: { user: userType }) => {
    const { user } = useUpsertUserData({ user: props.user });
    const { messages, loading } = useGetMessages();
    const { addMessage } = useAddMessages();
    const { uploadImage, imageUrl, uploading, transferred } = useUpload();

    const onButtonSendClick: onButtonSendClickType = (messageText: string): void => {
        addMessage({ text: messageText });
    };

    const onButtonAttachPhoto: buttonAttachPhotoType = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event && event!.currentTarget && event!.currentTarget.files) {
            const uploadFile = event!.currentTarget!.files[0];
            uploadImage(uploadFile);
        }
    };

    useEffect(() => {
        if (imageUrl) {
            // console.log('Add image url', imageUrl);
            addMessage({ image: { url: imageUrl } });
        }
    }, [imageUrl]);

    const isDrawer = true;

    return (
        <div className="card w-100 rounded-0" id="kt_drawer_chat_messenger">
            <ChatUserData user={user} />
            <div className="card-body" id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}>
                <MessagesContent messages={messages} user={user} loading={loading} />
                <SendContent
                    onButtonSendClick={onButtonSendClick}
                    onButtonAttachPhoto={onButtonAttachPhoto}
                    uploading={uploading}
                    transferred={transferred}
                />
            </div>
        </div>
    );
};
