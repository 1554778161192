import React from 'react';
import { KTSVG } from '../helpers';
import { userType } from '../types';
import { Avatar } from './Avatar';

export const ChatUserData = (props: { user: userType }) => {
    return (
        <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
            <div className="card-title">
                <div className="d-flex justify-content-center flex-column me-3">
                    <div>
                        <Avatar avatar={props.user?.avatar} name={props.user?.name} size={6} className="symbol symbol-35px symbol-circle" />
                        <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">{props.user?.name}</span>
                    </div>
                    <div className="mb-0 lh-1">
                        <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                        <span className="fs-7 fw-bold text-gray-400">Active</span>
                    </div>
                </div>
            </div>

            <div className="card-toolbar">
                <div className="me-2">
                    <button
                        className="btn btn-sm btn-icon btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end">
                        <i className="bi bi-three-dots fs-3"></i>
                    </button>
                </div>

                <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_chat_close">
                    <KTSVG path="/media/icons/duotone/Navigation/Close.svg" className="svg-icon-2" />
                </div>
            </div>
        </div>
    );
};
