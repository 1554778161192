import moment from 'moment';
import 'moment/locale/es';
import React from 'react';

moment().locale('es');

const MessageMoment = (props: { createdAt: number | undefined }) => {
    return props.createdAt ? (
        <span className="text-muted fs-7 mb-1 ml-2 mr-2 bg-light-success">{moment(new Date(props.createdAt)).fromNow()}</span>
    ) : null;
};

export default MessageMoment;
