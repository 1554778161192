import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { userType } from '../../types';

class Firebase {
    db: firebase.database.Database;
    storage: firebase.storage.Storage;
    firestore: firebase.firestore.Firestore;
    currentUserData: userType;

    constructor(currentUser: userType, firebaseConfig: Object) {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        } else {
            firebase.app();
        }
        this.db = firebase.database();
        this.storage = firebase.storage();
        this.firestore = firebase.firestore();
        this.currentUserData = currentUser;
    }

    // User API
    user = (uid: string) => this.db.ref(`message-chat/users/${uid}`);
    users = () => this.db.ref('message-chat/users');
    currentUser = () => this.currentUserData;

    // Message API
    message = (uid: string) => this.db.ref(`messages/${uid}`);
    messages = () => this.db.ref('messages');

    badwords = () => this.db.ref('badWords');

    // Upload API
    storageRef = (): firebase.storage.Reference => this.storage.ref(`chatuploads/`);
}

export default Firebase;
